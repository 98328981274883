<template>
  <div class="embedded-review">
   
    <div class="wrapper">
      <div class="header-review">
        <h2>CUSTOMER REVIEW</h2>
      </div>
      <i id="left" class="el-icon-arrow-left"></i>
      <ul class="carousel">
        <li class="card" v-for="(review, index) in reviewLists" :key="index">
          <div class="img">
            <img
              v-if="review['filename']"
              :src="review['filename']"
              alt="img"
              draggable="false"
            />
            <span v-if="!review['filename']" class="initials-name">{{ review['initials'] }}</span>
          </div>
          <h2>{{ review['customer_name'] }}</h2>
          <span
            ><i v-for="n in review['customer_feedback_point']" :key="n" class="review-point el-icon-star-on"></i
            >
          </span>
          <span class="customer-feedback">{{ review['customer_feedback']}}</span>
          <span class="customer-review">{{ review['customer_comment'] || '-' }}</span>
          <span class="feedback-date">{{ dateFormat(review['reported_date'])}}</span>
        </li>
      </ul>
      <i id="right" class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils'
import ReviewApi from "@/api/review";
export default {
  data(){
    return {
      loading: false,
      reviewLists: []
    }
  },
  methods: {
    loadData(){
      this.loading = true;
      ReviewApi.getReview().then(({result, data})=>{
        
        if(result){
          this.reviewLists = data;
        }
        setTimeout(()=>{
          this.loading = false;
          this.caroudelSetup()
        }, 1000)
      })
    },
    dateFormat(date){
      return Utils.dateFormat(date, 'DD/MM/YYYY')
    },
    caroudelSetup() {
      const wrapper = document.querySelector(".wrapper");
      const carousel = document.querySelector(".carousel");
      const firstCardWidth = carousel.querySelector(".card").offsetWidth;
      const arrowBtns = document.querySelectorAll(".wrapper i");
      const carouselChildrens = [...carousel.children];

      let isDragging = false,
        isAutoPlay = true,
        startX,
        startScrollLeft,
        timeoutId;

      // Get the number of cards that can fit in the carousel at once
      let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

      // Insert copies of the last few cards to beginning of carousel for infinite scrolling
      carouselChildrens
        .slice(-cardPerView)
        .reverse()
        .forEach((card) => {
          carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
        });

      // Insert copies of the first few cards to end of carousel for infinite scrolling
      carouselChildrens.slice(0, cardPerView).forEach((card) => {
        carousel.insertAdjacentHTML("beforeend", card.outerHTML);
      });

      // Scroll the carousel at appropriate postition to hide first few duplicate cards on Firefox
      carousel.classList.add("no-transition");
      carousel.scrollLeft = carousel.offsetWidth;
      carousel.classList.remove("no-transition");

      // Add event listeners for the arrow buttons to scroll the carousel left and right
      arrowBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
          carousel.scrollLeft +=
            btn.id == "left" ? -firstCardWidth : firstCardWidth;
        });
      });

      const dragStart = (e) => {
        isDragging = true;
        carousel.classList.add("dragging");
        // Records the initial cursor and scroll position of the carousel
        startX = e.pageX;
        startScrollLeft = carousel.scrollLeft;
      };

      const dragging = (e) => {
        if (!isDragging) return; // if isDragging is false return from here
        // Updates the scroll position of the carousel based on the cursor movement
        carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
      };

      const dragStop = () => {
        isDragging = false;
        carousel.classList.remove("dragging");
      };

      const infiniteScroll = () => {
        // If the carousel is at the beginning, scroll to the end
        if (carousel.scrollLeft === 0) {
          carousel.classList.add("no-transition");
          carousel.scrollLeft = carousel.scrollWidth - 2 * carousel.offsetWidth;
          carousel.classList.remove("no-transition");
        }
        // If the carousel is at the end, scroll to the beginning
        else if (
          Math.ceil(carousel.scrollLeft) ===
          carousel.scrollWidth - carousel.offsetWidth
        ) {
          carousel.classList.add("no-transition");
          carousel.scrollLeft = carousel.offsetWidth;
          carousel.classList.remove("no-transition");
        }

        // Clear existing timeout & start autoplay if mouse is not hovering over carousel
        clearTimeout(timeoutId);
        if (!wrapper.matches(":hover")) autoPlay();
      };

      const autoPlay = () => {
        if (window.innerWidth < 800 || !isAutoPlay) return; // Return if window is smaller than 800 or isAutoPlay is false
        // Autoplay the carousel after every 2500 ms
        timeoutId = setTimeout(
          () => (carousel.scrollLeft += firstCardWidth),
          2500
        );
      };
      autoPlay();

      carousel.addEventListener("mousedown", dragStart);
      carousel.addEventListener("mousemove", dragging);
      document.addEventListener("mouseup", dragStop);
      carousel.addEventListener("scroll", infiniteScroll);
      wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
      wrapper.addEventListener("mouseleave", autoPlay);
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.embedded-review {
  display: flex;
  padding: 0 35px;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to left top, #39bae7, #3a66e6);

  .wrapper {
    max-width: 1100px;
    width: 100%;
    position: relative;

    .header-review{
      text-align: center;
      h2{
        color: #fff;
        font-size: 2rem;
      }
      margin-bottom: 2rem;
    }
  }

  .wrapper #left,
  .wrapper #right {
    top: 60%;
    height: 50px;
    width: 50px;
    cursor: pointer;
    font-size: 1.25rem;
    position: absolute;
    text-align: center;
    line-height: 50px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
    transform: translateY(-50%);
    transition: transform 0.1s linear;
    z-index: 999;
  }

  .wrapper #left:active,
  .wrapper #right:active {
    transform: translateY(-50%) scale(0.85);
  }

  .wrapper #left {
    left: -22px;
  }

  .wrapper #right {
    right: -22px;
  }

  .wrapper .carousel {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% / 3) - 12px);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 16px;
    border-radius: 8px;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  .carousel::-webkit-scrollbar {
    display: none;
  }

  .carousel.no-transition {
    scroll-behavior: auto;
  }

  .carousel.dragging {
    scroll-snap-type: none;
    scroll-behavior: auto;
  }

  .carousel.dragging .card {
    cursor: grab;
    user-select: none;
  }

  .carousel :where(.card, .img) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel .card {
    scroll-snap-align: start;
    height: 342px;
    list-style: none;
    background: #fff;
    cursor: pointer;
    padding-bottom: 15px;
    flex-direction: column;
    border-radius: 8px;
  }

  .carousel .card .img {
    background: #39bae7;
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  .card .img img {
    width: 92px;
    height: 92px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #fff;
  }

  .carousel .card h2 {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 30px 0 5px;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
    width: 280px; 
    text-align: center;
  }

  .carousel .card span {
    color: #6a6d78;
    font-size: 1.31rem;
  }
  .carousel .card span.customer-review {
    color: #6a6d78;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
    width: 280px; 
    text-align: center;
  }

  .carousel .card .review-point {
    color: #fec559;
  }

  .carousel .card .customer-feedback {
    font-size: 0.75rem;
    color: #fec559;
  }

  .carousel .card .feedback-date {
    font-size: 0.75rem;
    color: #c0c4cc;
  }

  .card .img .initials-name {
    font-size: 3rem;
    color: #fff;
  }

  @media screen and (max-width: 900px) {
    .wrapper .carousel {
      grid-auto-columns: calc((100% / 2) - 9px);
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper .carousel {
      grid-auto-columns: 100%;
    }
  }
}
</style>
